.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.svg-overlay {
  height: clamp(50vh, 30vw, 70vh);
}


@media only screen and (max-width: 480px) {
  .Toastify {
    position: absolute;
    z-index: 10000 !important;
  }

  .Toastify__toast-container--bottom-right {
    z-index: 10000 !important;
    bottom: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    transform: translateX(0);
  }
}