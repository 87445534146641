@tailwind base;

@layer base {
  :root {
    /*
    *
    * This all are the rgb values which are used inside the
    * tailwind config file and custom plugins css file
    *
    */

    /*
    * light color
    */
    --color-light: 255, 255, 255;

    /*
    * dark color
    */
    --color-dark: 0, 0, 0;

    /*
    * primary color
    */
    --color-primary: 255, 205, 60;

    /*
    * secondary color
    */
    --color-secondary: 140, 145, 163;

    /*
    *
    * TEXT colors
    *
    */

    /*
    * text color used for body/base text -> gray-500
    */
    --text-base: 107, 114, 128;

    /*
    * dark base text color  -> gray-600
    */
    --text-base-dark: 75, 85, 99;

    /*
    * muted text color -> gray-400
    */
    --text-muted: 156, 163, 175;

    /*
    * light muted text color - gray-300
    */
    --text-muted-light: 209, 213, 219;

    /*
    * text color used for heading text -> gray-700
    */
    --text-sub-heading: 55, 65, 81;

    /*
    * text color used for heading text -> gray-800
    */
    --text-heading: 31, 41, 55;

    /*
    * text color used for heading text -> gray-900
    */
    --text-bolder: 31, 41, 55;

  }

  html {
    -webkit-tap-highlight-color: transparent;
  }

  body {
    @apply font-inter;
    background: #2e2e2e;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-inter;
  }

  a {
    @apply no-underline;
  }

  .form-label {
    @apply mb-0.5 text-primary text-sm leading-6 capitalize cursor-pointer block w-full font-medium rtl:text-right rtl:block;
  }

  .form-control {
    @apply bg-white transition duration-300 ease-in-out focus:ring-0 focus:ring-slate-600 focus:outline-none focus:ring-opacity-90 placeholder:text-slate-400 text-slate-900 text-sm px-0.5 block w-full;
  }

  .input-description {
    @apply block text-slate-500 font-light leading-4 text-xs mt-2;
  }

  .fromGroup {
    @apply relative;
  }

  .fromGroup.has-error .form-control {
    @apply border-red-500 focus:ring-red-500 focus:ring-opacity-90 focus:ring-1;
  }

  .fromGroup.is-valid .form-control {
    @apply border-green-500 focus:ring-green-500 focus:ring-opacity-90 focus:ring-1;
  }

  .form-control[readonly] {
    @apply bg-slate-200 text-slate-400 cursor-pointer placeholder:text-slate-400;
  }

  .form-control[disabled] {
    @apply cursor-not-allowed bg-white text-slate-400 placeholder:text-slate-400;
  }
}

::-webkit-scrollbar {
  display: none;
}

@layer utilities {
  .svg-overlay {
    /* height: clamp(50vh, 30vw, 70vh) !important; */
    width: 100% !important;
  }

  @media only screen and (max-width: 480px) {
    .Toastify {
      position: absolute;
      z-index: 10000 !important;
    }

    .Toastify__toast-container--bottom-right {
      z-index: 10000 !important;
      bottom: 20px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
      transform: translateX(0);
    }
  }

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
  }

  .list {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 8px;
  }

  .list-overlay {
    background: #2090FF;
  }

  .button {
    display: flex;
    height: 34px;
    align-items: center;
    gap: 8px;
    border-radius: 100%;
    padding: 16px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
  }

  .clip-path-container {
    position: absolute;
    z-index: 10;
    width: 100%;
    overflow: hidden;
    transition: clip-path 0.25s ease;
    clip-path: inset(0px 75% 0px 0% round 17px);
  }

}

@tailwind components;
@tailwind utilities;